@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

html,
body {
  font-family: "Open Sans", sans-serif;
}

/*===== Tailwind type Custom CSS for Hover effect =====*/
.hover-trigger .hover-target-block,
.hover-trigger .hover-target-flex {
  display: none;
}
.hover-trigger:hover .hover-target-block {
  display: block;
}
.hover-trigger:hover .hover-target-flex {
  display: flex;
}

/*===== Custom styling to override Windmill UI and Tailwind CSS  =====*/

/*~~~ Underline Hover effect for Dark Theme */
.theme-dark .dark\:underline {
  text-decoration: underline;
}

thead {
  background: transparent !important;
}

/*===== Table Pagination =====*/
.common-table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.common-table-pagination > span {
  font-weight: normal;
}
.common-table-pagination nav button {
  width: 33px;
  height: 33px;
  padding: 0;
  border-radius: 50%;
}
.common-table-pagination nav button.bg-purple-600,
.common-table-pagination nav button.bg-purple-600:hover {
  background-color: #305acc;
}
.common-table-pagination nav button:hover {
  --bg-opacity: 1;
  /* below style is of bg-gray-300 */
  background-color: rgba(213, 214, 215, var(--bg-opacity));
}
/* active:bg-purple-600 hover:bg-purple-700 focus:shadow-outline-purple */
.common-table-pagination nav button.hover\:bg-purple-600:hover,
.common-table-pagination nav button.focus\:bg-purple-600:focus,
.common-table-pagination nav button.active\:bg-purple-600:active,
.common-table-pagination nav button.active\:bg-purple-600.active {
  background-color: #305acc;
}
@media only screen and (max-width: 767.98px) {
  .common-table-pagination {
    flex-direction: column;
  }
}

/*===== RC Timepicker =====*/
.rc-timepicker .rc-time-picker-input {
  border: 0;
  padding: 0;
}
.rc-timepicker .rc-time-picker-input:focus {
  outline: none;
}
.rc-timepicker .rc-time-picker-clear {
  top: 50%;
  transform: translateY(-50%);
}
.rc-timepicker .rc-time-picker-clear:focus {
  outline: none;
}
.rc-timepicker .rc-time-picker-clear-icon:after {
  font-size: 14px;
  color: black;
}
.theme-dark .dark\:bg-transparent .rc-time-picker-input {
  background-color: transparent;
}
.theme-dark .dark\:bg-transparent .rc-time-picker-input {
  color: white;
}
.theme-dark
  .dark\:bg-transparent
  .rc-timepicker
  .rc-time-picker-clear-icon:after {
  color: white;
}
.theme-dark .rc-timepicker .rc-time-picker-clear-icon:after {
  color: white;
}
@media (min-width: 640px) {
  .rc-timepicker .rc-time-picker-input {
    color: black;
    line-height: 1.25rem;
    font-size: 0.875rem;
  }
}

/*===== React Datepicker===== */
.react-datepicker-wrapper {
  display: block;
}
.custom-react-datepicker.form-input:focus {
  outline: none;
  box-shadow: none;
}

/*  */
.dashboard-calendar .react-datepicker-popper {
  left: unset !important;
  right: 0 !important;
}

/*===== Table Overflow Horizontal Scrollbar Style =====*/
/* ~~~ Place this class in <TableContainer> element */
.table-overflow-x *::-webkit-scrollbar {
  height: 6px;
}
.table-overflow-x *::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  border-radius: 0;
}
.table-overflow-x *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
}
.table-overflow-x:hover ::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.25);
}

.theme-dark .dark\:table-overflow-x *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.25);
}
.theme-dark .dark\:table-overflow-x:hover *::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.65);
}

/*===== Custom Radio - used in UpsertOrder page =====*/
.custom-radio-group label {
  min-height: 38px;
  vertical-align: middle;
}
.custom-radio-group input[type="radio"]:checked + label {
  background: #3f83f8;
  border-color: #3f83f8;
  color: white;
}
.theme-dark .custom-radio-group input[type="radio"]:checked + label {
  background: #d5d6d7;
  border-color: #d5d6d7;
  color: black;
}

/*===== Custom Nav One =====*/
.custom-nav-one {
  display: flex;
  align-items: center;
}
.custom-nav-one a {
  position: relative;
}
.custom-nav-one a:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 4px;
  background: transparent;
}
.custom-nav-one a.active {
  color: #76a9fa !important;
}
.custom-nav-one a.active:after {
  background: #5145cd;
}

/*===== Activity Chart =====*/
.activity-chart > ul {
  position: relative;
}
.activity-chart > ul:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 31px;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
}

/*===== Packages Block =====*/
.packages-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.package-block-wrapper {
  width: 100%;
  padding: 0 15px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 768px) {
  .packages-wrapper {
    padding: 0 15px;
    margin-left: -15px;
    margin-right: -15px;
  }
  .package-block-wrapper {
    width: 50%;
  }
}
@media only screen and (min-width: 1680px) {
  .packages-wrapper {
    padding: 0 5rem;
  }
  .package-block-wrapper {
    width: 33%;
  }
  /*===== PhoneInput =====*/
  .react-tel-input .form-control {
    width: 100%;
    height: 38px;
  }
  .theme-dark .react-tel-input .form-control {
    background: transparent;
    color: white;
  }
  .theme-dark .react-tel-input .flag-dropdown {
    background: transparent;
  }
  .theme-dark .react-tel-input .selected-flag:hover,
  .theme-dark .react-tel-input .selected-flag:focus {
    background: transparent;
  }
}
